let open = false;

let toggleNavigation = () => {
    let btn = document.querySelector('#navigation__button');
    let btnIcon = document.querySelector('#button__icon');
    let toggleState = btn.getAttribute('toogle');
    let navigation = document.querySelector('#navigation__mobile');
    console.log(toggleState);
    if (toggleState === 'false') {
      btn.setAttribute('toogle', 'true');
      btnIcon.src = 'assets/icons/menu-close.svg';
      navigation.style.animationName = 'slideIn';

    } else {
      btn.setAttribute('toogle', 'false');
      btnIcon.src = 'assets/icons/menu-show.svg';
      navigation.style.animationName = 'slideOut';

    }
  };

function initNavigationController() {
  let navButton = document.querySelector('#navigation__button');

  if (navButton) {
    navButton.addEventListener('click', toggleNavigation.bind(navButton), false);
    navButton.setAttribute('toogle', 'false');
  }
}
