function getCurrentPageName() {

  let pageName = window.location.href.split('/')[3].split('.')[0];
  console.log(pageName);
  return pageName;
}

function getUrlParam(paramName) {
  return new URLSearchParams(window.location.search).get(paramName);
}

function initBrowserUtils() {
  let currentPageName = getCurrentPageName();
  let currentPageParam = getUrlParam('id');
  alert('The current page name is: ' + currentPageName);
  alert('The current page param is: ' + currentPageParam);
}
