function initCurrentPage() {
  let document = getCurrentPageName();

  switch (document) {
    case 'index':

      console.log(document + ' is loaded');
      break;
    case 'gallery':

      console.log(document + ' is loaded');
      initSlideShow();
      break;
    case 'contact':

      initContact();
      console.log(document + ' is loaded');
    default:
      break;
  }
}

window.addEventListener('DOMContentLoaded', function () {
    initNavigationController();
    initCurrentPage();

  }, false);
