
let slideshowContainer = document.querySelector('#slideshow__container');
let prevButton = document.querySelector('#slideshow__navigation__button--prev');
let playButton = document.querySelector('#slideshow__navigation__button--play');
let detailsButton = document.querySelector('#slideshow__navigation__button--details');
let nextButton = document.querySelector('#slideshow__navigation__button--next');
let slideElements = document.getElementsByClassName('slide');
let index = 0;
let interval;
let play = true;
const mockImages = [{
      collectionID: "ringe",
      productImage: "r_01",
    },
    {
      collectionID: "ringe",
      productImage: "r_02",
    },
    {
      collectionID: "ringe",
      productImage: "r_03",
    },
    {
      collectionID: "ringe",
      productImage: "r_04",
    },
    {
      collectionID: "ringe",
      productImage: "r_05",
    },
    {
      collectionID: "ringe",
      productImage: "r_06",
    },
    {
      collectionID: "ohrschmuck",
      productImage: "o_01",
    }, {
      collectionID: "ohrschmuck",
      productImage: "o_02",
    }, {
      collectionID: "ohrschmuck",
      productImage: "o_03",
    }, {
      collectionID: "ohrschmuck",
      productImage: "o_04",
    }, {
      collectionID: "ohrschmuck",
      productImage: "o_05",
    }, {
      collectionID: "ohrschmuck",
      productImage: "o_06",
    },
    {
      collectionID: "armschmuck",
      productImage: "a_01",
    }, {
      collectionID: "armschmuck",
      productImage: "a_02",
    }, {
      collectionID: "armschmuck",
      productImage: "a_03",
    }, {
      collectionID: "armschmuck",
      productImage: "a_04",
    }, {
      collectionID: "halsschmuck",
      productImage: "h_01",
    }, {
      collectionID: "halsschmuck",
      productImage: "h_02",
    }, {
      collectionID: "halsschmuck",
      productImage: "h_03",
    }, {
      collectionID: "halsschmuck",
      productImage: "h_04",
    }, {
      collectionID: "halsschmuck",
      productImage: "h_05",
    }, {
      collectionID: "halsschmuck",
      productImage: "h_06",
    }, {
      collectionID: "halsschmuck",
      productImage: "h_07",
    }, {
      collectionID: "eheringe",
      productImage: "er_01",
    }, {
      collectionID: "eheringe",
      productImage: "er_02",
    }, {
      collectionID: "eheringe",
      productImage: "er_03",
    }, {
      collectionID: "eheringe",
      productImage: "er_04",
    }, {
      collectionID: "eheringe",
      productImage: "er_05",
    }, {
      collectionID: "eheringe",
      productImage: "er_06",
    }, {
      collectionID: "eheringe",
      productImage: "er_07",
    }, {
      collectionID: "eheringe",
      productImage: "er_08",
    },
  ];

let products = mockImages.filter((product) => product.collectionID == getUrlParam('id'));

function getBackground() {
    let background = document.querySelector('#gallery__main');
      const urlParams = new URLSearchParams(window.location.search);
      const collectionID = urlParams.get('id');
      let directory = 'assets/images/bg_' + collectionID + '.png';
      let url = 'url('+directory+')';
      background.style.backgroundImage = url;
  }

function createSlides() {
  products.forEach(function (entry, index) {
    let slide = document.createElement('img');
    slide.className = 'slide';
    slide.src = 'assets/images/' + products[index].productImage + '.svg';
    slideshowContainer.insertBefore(slide, slideshowContainer.firstChild);
    slide.id = 'slide-' + index;
  });
}

function stopSlideShow() {
  if (play) {
    clearInterval(interval);
    playButton.src = 'assets/icons/play.svg';
    play = false;
  }else {
    interval = window.setInterval(showNextPicture, 3000);
    playButton.src = 'assets/icons/break.svg';
    play = true;
  }

}

function showPreviewPicture() {
  if (index > 0 || index !== 0) {
    index--;
    let activeSlide = document.querySelector('.slide--active');
    activeSlide.classList.add('fadeOut');
    activeSlide.classList.remove('slide--active');
    let nextSlide = document.querySelector('#slide-' + index);
    nextSlide.classList.add('slide--active');
  }

}

function showDetails() {
  console.log('show Details');
}

function showNextPicture() {
  let limit = products.length - 1;
  if (index < limit) {
    let activeSlide = document.querySelector('.slide--active');
    activeSlide.classList.add('fadeOut');
    activeSlide.classList.remove('slide--active');
    index++;

    let nextSlide = document.querySelector('#slide-' + index);
    nextSlide.classList.add('slide--active');
  } else {
    index = -1;
    let activeSlide = document.querySelector('.slide--active');
    activeSlide.classList.add('fadeOut');
    activeSlide.classList.remove('slide--active');
    index++;

    let nextSlide = document.querySelector('#slide-' + index);
    nextSlide.classList.add('slide--active');
  }

}

function showDefaultPicture() {
  let defaultSlide = document.querySelector('#slide-0');
  defaultSlide.classList.add('slide--active');
}

function initSlideShow() {
  getBackground();
  interval = window.setInterval(showNextPicture, 3000);
  createSlides();
  showDefaultPicture();


  if (prevButton) {
    prevButton.addEventListener('click', showPreviewPicture, false);
  }

  if (playButton) {
    playButton.addEventListener('click', stopSlideShow, false);
  }

  if (detailsButton) {
    detailsButton.addEventListener('click', showDetails, false);
  }

  if (nextButton) {
    nextButton.addEventListener('click', showNextPicture, false);
  }

  if (slideElements) {
    for (var i = 0; i < products.length; i++) {
      slideElements[i].addEventListener('mouseover', stopSlideShow, false);
    }

  }

}
